import React, { useState } from 'react';
import BillingNavbar from './billingNavbar';
import Page from '../Page';


export default function Payments() {
    const [email, setEmail] = useState("email@example.com");
    const [emailNotification, setEmailNotification] = useState(true);
    const [name, setName] = useState("Duarte Barreiros Ferreira");
    const [street, setStreet] = useState("Rua Xavier de Araujo");
    const [countryCode, setCountryCode] = useState("Lisboa, Lisboa 1600-209");
    const [country, setCountry] = useState("Portugal");

    const [primary, setPrimary] = useState("Credit card");
    const [ending, setEnding] = useState("3902");
    const [expires, setExpires] = useState("03/26");

    const [secondary, setSecondary] = useState("Credit card");
    const [endingSecondary, setEndingSecondary] = useState("4873");
    const [expiresSecondary, setExpiresSecondary] = useState("04/26");
    return (
        <Page>
            <BillingNavbar>
                <div className='flex flex-col gap-10 w-full'>
                    <div className='border-2 border-zinc-200 rounded-lg mil:flex gap-2 w-full'>
                        <div className=' p-8  mil:w-[70%]'>
                            <p className='text-xl font-bold'>Payment methods</p>
                            <p className=' text-zinc-500 pt-4'>Recurring monthly charges will be billed to your primary payment method. A backup
                            payment method is to be used if your primary payment method fails.</p>
                            <div className='grid grid-cols-2 gap-10 pt-8'>
                                <div>
                                    <p className='text-zinc-500 font-bold pb-2'>Primary</p>
                                    <p className='text-zinc-500'>{primary}</p>
                                    <p className='text-zinc-500'>Ending in {ending}</p>
                                    <p className='text-zinc-500'>Expires on {expires}</p>
                                </div>
                                <div>
                                    <p className='text-zinc-500 font-bold pb-2'>BackUp</p>
                                    <p className='text-zinc-500'>{secondary}</p>
                                    <p className='text-zinc-500'>Ending in {endingSecondary}</p>
                                    <p className='text-zinc-500'>Expires on {expiresSecondary}</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-zinc-100 mil:w-[30%] p-4 border-t-2 mil:border-t-0 mil:border-l-2 border-zinc-200 rounded-lg flex justify-center items-center'>
                            <button className='bg-indigo-400 text-white py-2 px-6 rounded-lg w-32'>Manage</button>
                        </div>
                    </div>
                    <div className='border-2 border-zinc-200 rounded-lg mil:flex gap-2 w-full'>
                        <div className='flex flex-col gap-4 p-8  mil:w-[70%]'>
                            <p className='text-xl font-bold'>Billing address</p>
                            <div className='italic flex flex-col gap-2'>
                                <p className=' text-zinc-500'>{name}</p>
                                <p className=' text-zinc-500'>{street}</p>
                                <p className=' text-zinc-500'>{countryCode}</p>
                                <p className=' text-zinc-500'>{country}</p>
                            </div>
                        </div>
                        <div className='bg-zinc-100 mil:w-[30%] p-4 border-t-2 mil:border-t-0 mil:border-l-2 border-zinc-200 rounded-lg flex justify-center items-center'>
                            <button className='bg-indigo-400 text-white py-2 px-6 rounded-lg w-32'>Edit</button>
                        </div>
                    </div>
                    <div className='w-full flex gap-2'>
                        <p className='text-zinc-500'>Are you tax exempt?</p>
                        <a href='' className='underline text-indigo-400'>Understanding Cloudflare sales tax</a>
                    </div>
                </div>
            </BillingNavbar>
        </Page>

    );
}

