import React, { useCallback, useEffect, useState } from 'react';
import Page from '../Page';
import { IoHelpCircleSharp } from "react-icons/io5";
import { IoIosClose, IoIosSearch } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/loading';
import { useUser } from '../../contexts/userContext';
import Modal from '../../components/Modal';
import Wheel from '@uiw/react-color-wheel';
import { hsvaToHex } from '@uiw/color-convert';
import { FaCheck } from 'react-icons/fa';
import { Team } from '../../interfaces/Team';
import { TeamMember } from '../../interfaces/TeamMember';
import { clearCache, fetchData, postData } from '../../components/DataHandler';
import Toaster from '../../components/Toaster';
import { HSVA } from '../../interfaces/HSVA';
import "../../components/customScroll.styles.scss";

export default function AllTeams() {
    const lang = navigator.language || 'en';
    const [hsva, setHsva] = useState<HSVA>({ h: 214, s: 43, v: 90, a: 1 });
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const { user } = useUser();
    const [teamName, setTeamName] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedMembers, setSelectedMembers] = useState<string[]>([]); // Array of selected members to add to the team
    const [teams, setTeams] = useState<Team[]>([]);
    const [filteredTeams, setFilteredTeams] = useState<Team[]>([]);
    const [teamElements, setTeamElements] = useState<TeamMember[]>([]);
    const [search, setSearch] = useState<string>('');
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [hasCompany, setHasCompany] = useState<boolean>(false);

    const TeamCard = ({ team }: { team: Team }) => {
        // TODO: Missing the max number of members
        return (
            <div onClick={() => navigate(team.name, { state: { name: team.name, number: team.team_number, color: `#${team.color_code}`, id: team.id } })} className={`border-2 border-zinc-300 rounded-2xl p-4 ${team.status && "cursor-pointer"} flex flex-col gap-4`}>
                <div className="flex items-center justify-between">
                    <span className="text-zinc-500 text-xl">{team.name}</span>
                    <div style={{ backgroundColor: `#${team.color_code}` }} className="w-8 h-8 text-white rounded-full flex items-center justify-center">{team.team_number}</div>
                </div>
                <div className="flex items-center gap-2">
                    <div className={`w-3 h-3 ${team.status ? "bg-lime-400 " : "bg-red-400 "} rounded-full`}></div>
                    <span className="text-black">{team.status ? "Active" : "Inactive"}</span>
                </div>
                <span className="text-zinc-400">{team.numMembers}/{30} membros</span>
                {/* <span className="text-zinc-400">{team.numMembers}/{team.maxMembers} membros</span>*/}
            </div>
        )
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };


    const fetchTeams = useCallback(async () => {
        const idToken = user ? user!.idToken : null;

        setLoading(true);
        const response = await fetchData("/api/teams/teamsManaging", idToken);
        if (response.status === 200 || response.status === 201) {
            setTeams(response.data.teams);
            setFilteredTeams(response.data.teams);
            setTeamElements(response.data.employees);
            setHasCompany(true);
        } else if (response.status === 204) {
            setHasCompany(false);
        } else {
            Toaster.show('Não foi possível obter as equipas. Tente novamente.', 'error');
            console.error('Get teams failed');
        }
        setLoading(false);
    }, [user]);

    const handleTeamCreate = async () => {

        const idToken = user ? user!.idToken : null;

        if (teamName === '' || (hsva.h !== 214 && hsva.s !== 43 && hsva.v !== 90 && hsva.a !== 1)) {
            Toaster.show('Preencha o nome e selecione uma cor.', 'error');
            return;
        }

        setLoading(true);
        const teamObj = {
            name: teamName,
            colorCode: hsvaToHex(hsva).substring(1),
            usersMails: selectedMembers
        }
        const response = await postData("/api/teams/create", teamObj, idToken);
        if (response.status === 200 || response.status === 201) {
            const newTeams = [
                ...teams,
                {
                    name: teamName,
                    color_code: hsvaToHex(hsva).substring(1),
                    id: response.data.id,
                    team_number: response.data.team_number,
                    status: response.data.status,
                    numMembers: response.data.numMembers
                }
            ];
            setTeams(newTeams);
            setFilteredTeams(newTeams);
            clearCache("/api/teams/teamsManaging");
            clearCache("/accountInfo");
            fetchTeams();
            Toaster.show(`Equipa ${teamName} criada com sucesso.`, 'success');
        } else {
            Toaster.show('Não foi possível criar uma equipa. Tente novamente.', 'error');
            console.error('Create team failed');
        }
        setLoading(false);
        setTeamName('');
        setSelectedMembers([]);
        setHsva({ h: 214, s: 43, v: 90, a: 1 });
        setShowModal(false);
    }

    const toggleCancel = () => {
        setTeamName('');
        setHsva({ h: 214, s: 43, v: 90, a: 1 });
        setShowModal(false);
    }


    const handleAddMember = (username: string) => {
        if (selectedMembers.includes(username)) {
            setSelectedMembers(selectedMembers.filter((elem) => elem !== username));
        } else {
            setSelectedMembers([...selectedMembers, username]);
        }
    };

    useEffect(() => {
        fetchTeams();
    }, [fetchTeams]);


    useEffect(() => {
        if (search === '') {
            setFilteredTeams(teams);
            return;
        }
        const searchNumber = Number(search);
        const isSearchNumber = !isNaN(searchNumber);
        //if search is number filter by number, else filter by name
        const filtered = teams.filter((team) =>
            isSearchNumber ? team.team_number === searchNumber :
                team.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredTeams(filtered);
        if (filtered.length === 0) {
            Toaster.show(`Não foram encontrados resultados para a pesquisa: ${search}`, 'error');
        }
    }, [search, teams]);

    return (
        <Page >

            <div className='h-screen'>
                <div className='flex'>
                    <h2 className='text-xl'>{lang === "pt" ? "Equipas" : "Teams"}</h2>
                </div>
                <div className="py-6">
                    <div className="flex flex-wrap gap-2 pb-6">
                        {teams && teams.map((team, index) => (
                            <div key={index} style={{ backgroundColor: `#${team.color_code}` }} className={`w-8 h-8 text-white rounded-full flex items-center justify-center`}>{team.team_number}</div>
                        ))}
                    </div>
                    <div className="flex items-center gap-2 justify-between pb-6">
                        <div className='flex gap-2 w-full items-center'>
                            <div className='group relative'>
                                <IoHelpCircleSharp className='text-zinc-500' size={40} />
                                <span className='absolute top-12 scale-0 group-hover:scale-100 transition-all duration-200 ease-in-out bg-zinc-100 w-60 aspect-square rounded-2xl p-2'>
                                    <p className='text-lg text-black'>Ajuda</p>
                                    <p className='text-sm text-zinc-500'>
                                        Poderá pesquisar por nome ou número de equipa.
                                        Equipas inativas são marcadas a vermelho e ativas a verde.
                                        As equipas ativas são as que têm menos membros do que o máximo permitido.
                                        Poderá adicionar uma nova equipa clicando no botão no canto superior direito e posteriormente adicionar novos membros.
                                    </p>
                                </span>
                            </div>
                            <div className="w-1/2">
                                <div className=" bg-gray-200 rounded-full shadow flex px-2 py-1 w-fit">
                                    <input type="text" name="search" className={`${showSearch ? "w-60 px-4" : "w-0"} transition-all duration-300 ease-in-out dark:text-gray-800 outline-none bg-transparent`} onChange={handleSearchChange} value={search} />
                                    <button type={showSearch ? "submit" : "button"}>
                                        <div>
                                            <IoIosSearch onClick={() => setShowSearch(!showSearch)} className='text-zinc-500' size={36} title="Pesquisar" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {hasCompany && <button onClick={() => setShowModal(true)} className="bg-dashBlue w-60 text-white py-2 px-4 rounded-full flex items-center justify-center gap-2">
                            <span>+</span>
                            <span>Nova Equipa</span>
                        </button>}
                    </div>
                    {hasCompany ?
                        <div >
                            {(filteredTeams && filteredTeams.length > 0) ?
                                <div className="grid cinco:grid-cols-2 miledois:grid-cols-3 gap-10">
                                    {filteredTeams.map((team, index) => (
                                        <TeamCard key={index} team={team} />
                                    ))}
                                </div>
                                :
                                <div className='flex justify-center items-center gap-2'>Não existem equipas. Crie uma nova equipa clicando no botão no canto superior direito.</div>}
                        </div>
                        :
                        <div className='flex justify-center items-center gap-2'>Não existe empresa. Crie uma nova empresa na página de definições <Link className='text-lg text-indigo-400 underline' to={"/settings"}>Settings.</Link></div>}
                </div>
            </div>
            <Loading isOpen={loading} />
            <Modal isOpen={showModal} handler={handleTeamCreate}>
                <div className='p-8'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-xl'>{lang === "pt" ? "Adicionar Equipa" : "Add Team"}</p>
                        </div>
                        <IoIosClose onClick={() => setShowModal(false)} className='cursor-pointer' size={40} title={lang === "pt" ? "Fechar" : "Close"} />
                    </div>
                    <div className='py-6'>
                        <div className='p-4 bg-zinc-100 text-zinc-400 rounded-xl flex items-center gap-2'>
                            <div><IoHelpCircleSharp size={24} title='help' /></div>
                            <p className='text-sm'>{lang === "pt" ?
                                "Insira o nome que deseja atribuir à nova equipa que terá de ser único. Poderá adicionar membros posteriormente." :
                                "Enter the name you want to assign to the new team, which must be unique. You can add members later."}
                            </p>
                        </div>
                        <div className='py-8 flex flex-col sete:flex-row justify-around sete:items-center gap-4'>
                            <div className='flex flex-col gap-8'>
                                <div className='flex flex-col gap-2'>
                                    <label htmlFor="teamName" className='text-sm'>{lang === "pt" ? "Nome da Equipa" : "Team Name"}</label>
                                    <input
                                        className="w-64 outline-none px-4 py-2 text-sm border border-gray-300 border-solid rounded-full"
                                        type="text"
                                        placeholder='| Name'
                                        onChange={(e) => setTeamName(e.target.value)}
                                        value={teamName}
                                    />
                                </div>
                                <div>
                                    <div className='flex gap-2'>
                                        <p>{lang === "pt" ? "Adicionar Membros à equipa " : "Add members to "}</p>
                                        <p style={{ color: teamName && hsvaToHex(hsva) }}>{teamName ? teamName : "..."}</p>
                                    </div>
                                    <div className='scroll overflow-auto max-h-80 pr-2'>
                                        <table className="min-w-full">
                                            <thead className="">
                                                <tr className='text-sm text-left text-zinc-500 '>
                                                    <th className="px-6 py-3 ">
                                                        {lang === "pt" ? "Nome" : "Name"}
                                                    </th>
                                                    <th className="px-6 py-3 ">
                                                        Email
                                                    </th>
                                                    <th className="px-6 py-3 ">
                                                        {lang === "pt" ? "Selecionar" : "Select"}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                                {teamElements && teamElements.map((elem, index) => (
                                                    <tr key={index} className=' text-zinc-500  border-t-2 border-zinc-200'>
                                                        <td className="px-6 py-10">
                                                            <div className="text-sm font-bold text-black rounded-2xl ">{elem.name}</div>
                                                        </td>
                                                        <td className="px-6 py-10  ">
                                                            <div className="text-sm rounded-2xl ">{elem.email}</div>
                                                        </td>
                                                        <td className="px-6 py-10 text-sm font-medium flex justify-center">
                                                            <div
                                                                className={`cursor-pointer border flex items-center justify-center border-black rounded-lg w-6 aspect-square ${selectedMembers.includes(elem.email) ? 'bg-blue-600 ' : ''}`}
                                                                onClick={() => handleAddMember(elem.email)}
                                                            >
                                                                <FaCheck className='text-white' />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col gap-6'>
                                <Wheel color={hsva} onChange={(color) => setHsva({ ...hsva, ...color.hsva })} />
                                <div className='flex justify-center'>
                                    <div className='flex gap-2 items-center'>
                                        <p className='text-xl text-zinc-500'>{lang === "pt" ? "Equipa" : "Team"}</p>
                                        <div className='rounded-full h-6 w-6' style={{ background: hsvaToHex(hsva) }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-8 justify-end'>
                        <button onClick={toggleCancel} className="bg-zinc-200 text-zinc-500 py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Cancelar" : "Cancel"}</span>
                        </button>
                        <button onClick={handleTeamCreate} className="bg-dashBlue text-white py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Adicionar" : "Add"}</span>
                        </button>

                    </div>
                </div>
            </Modal>
        </Page>

    );
}

