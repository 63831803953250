import React, { useEffect, useRef, useState } from 'react';
import BillingNavbar from './billingNavbar';
import Page from '../Page';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { PiCube } from 'react-icons/pi';
import "../../components/customScroll.styles.scss";
import { COLORS } from '../../content/colors';
import { ChartData } from '../../interfaces/ChartData';
import { useUser } from '../../contexts/userContext';
import { fetchData } from '../../components/DataHandler';
import Toaster from '../../components/Toaster';
import { Module } from '../../interfaces/Module';
import Loading from '../../components/Loading/loading';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default function UsageStats() {
    const { user } = useUser();
    const [loading, setLoading] = useState<boolean>(false);
    const lang = navigator.language || 'en';
    const [chartData, setChartData] = useState<ChartData[]>([]);
    const [modules, setmodules] = useState<Module[]>([]);
    const [activeModules, setActiveModules] = useState(0);
    const [requests, setRequests] = useState(0);
    const [totalPrices, setTotalPrices] = useState(0);
    const reportRef = useRef(null);

    const exportToPDF = async () => {
        if (reportRef.current) {
            const canvas = await html2canvas(reportRef.current);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const imgX = (pdfWidth - imgWidth * ratio) / 2;
            const imgY = 30;

            pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
            pdf.save('usage_stats_report.pdf');
        }
    };
    
    useEffect(() => {
        const fetchModules = async () => {
            const idToken = user ? user!.idToken : null;

            setLoading(true);
            const response = await fetchData("/api/modules/", idToken);
            if (response.status === 200 || response.status === 201) {
                setmodules(response.data);
            } else {
                Toaster.show('Não foi possível obter os modules. Tente novamente.', 'error');
                console.error('Get modules failed');
            }
            setLoading(false);
        }
        fetchModules();
    }, [user]);


    useEffect(() => {
        if (modules.length > 0) {
            let activeModules = 0 as number;
            let totalPrices = 0 as number;
            let chartData = [] as ChartData[];
            for (let i = 0; i < modules.length; i++) {
                if (modules[i].order_item_status) {
                    activeModules++;
                }
                totalPrices += Number(modules[i].price);
                chartData.push({ name: modules[i].name, value: Number(modules[i].price) });
            }
            setChartData(chartData);
            setActiveModules(activeModules);
            setTotalPrices(totalPrices);
        }
    }, [modules]);

    return (
        <Page>
            <BillingNavbar>
                <div ref={reportRef} className='flex flex-col mil:flex-row gap-10 justify-center' >
                    <div className='border-4 border-zinc-200 rounded-xl p-10 h-full w-full'>
                        <div >
                            <div className='flex justify-between items-center'>
                                <p className='text-lg font-bold'>{lang === "pt" ? "Total de Módules" : "Total Modules"}</p>
                                {/* <button onClick={() => console.log("more+")} className='flex gap-1'>
                                <p>more</p>
                                <p>+</p>
                            </button> */}
                            </div>
                            <div className='flex flex-wrap justify-between items-center h-full py-8'>
                                <div className='flex justify-center text-[40px]'>
                                    {activeModules}/{modules.length}
                                </div>
                                <div className='flex justify-center gap-2 items-center'>
                                    <div className={`w-6 h-6 bg-lime-400 text-white rounded-full flex items-center justify-center`}></div>
                                    <div className='text-xl'>Active</div>
                                </div>
                            </div>
                            <div className='flex py-6 justify-around gap-4'>
                                <p>Total Usage</p>
                                <p>{requests} requests</p>
                            </div>
                            <div>
                                <ResponsiveContainer minHeight={"10em"} height='100%' width='100%'>
                                    <PieChart>
                                        <Pie dataKey="value" paddingAngle={3} data={chartData} fill="#8884d8" innerRadius={"50%"} outerRadius={"100%"} >
                                            {
                                                chartData!.map((entry: any, index: number) => (
                                                    <Cell x={entry.name} y={entry.value} style={{ outline: "none" }} key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))
                                            }
                                        </Pie >
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                            <div className='flex flex-wrap py-6 gap-4'>
                                {modules.map((elem, index) => (
                                    <div key={index} className="py-1 flex justify-center">
                                        <div className='flex gap-1 items-center'>
                                            <div className="text-sm text-black rounded-2xl ">{elem.price}€</div>
                                            <PiCube size={24} color={COLORS[index % COLORS.length]} title={elem.name} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='w-full flex justify-center flex-col gap-4'>
                            <p className='text-center text-zinc-500'>Total: {totalPrices}€</p>
                            <button onClick={exportToPDF} className='bg-indigo-400 text-white py-2 px-6 rounded-lg'>Export Report</button>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4 scroll overflow-y-auto max-h-[42rem] pr-2  w-full'>

                        {modules.map((elem, index) => (
                            <div key={index} className="border-4 border-zinc-200 rounded-xl p-6">
                                <div className='flex justify-between gap-6' >
                                    <div className='flex gap-2 items-center justify-center'>
                                        <PiCube size={24} color={COLORS[index % COLORS.length]} title={elem.name} />
                                        <p>{elem.name}</p>
                                    </div>
                                    {/* <div className="text-sm text-black rounded-2xl flex items-center">{elem.plan}</div> */}
                                </div>
                                {/* <div className='py-4'>
                                    API requests: {elem.requests}
                                </div> */}
                                <div className='pb-4'>
                                    Payment: {elem.price}€
                                </div>
                                <div className='flex justify-end gap-2 items-end'>
                                    <div className={`w-6 h-6 ${elem.order_item_status ? "bg-lime-400" : "bg-red-500"}  text-white rounded-full flex items-center justify-center`}></div>
                                    <div >{elem.order_item_status ? "Active" : "Inactive"}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </BillingNavbar>
            <Loading isOpen={loading} />
        </Page>

    );
}