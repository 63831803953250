import React from 'react';
import BillingNavbar from './billingNavbar';
import Page from '../Page';
import { BsExclamationCircle } from "react-icons/bs";

export default function Subscriptions() {


    return (
        <Page>
            <BillingNavbar>
                <div className='flex flex-col gap-4'>
                <div className='w-full justify-end flex'>
                    <button className='bg-blue-300 hover:bg-blue-400 transition-all ease-in-out duration-300 text-white font-bold py-2 px-4 rounded-full flex justify-center items-center gap-2'>
                        <BsExclamationCircle color='#383b79' size={24} title='Billing Support' />
                        <p className='text-dashBlueDark font-semibold'>Billing Support</p>
                    </button>
                </div>
                <div className='w-full border-2 border-zinc-200 rounded-lg p-8 f'>
                    <p className='text-xl font-bold'>Subscriptions</p>
                    <p className='py-4'>All subscriptions will automatically renew at the end of your current service period unless noted otherwise. Any discounts being applied will be reflected on your invoice.</p>
                    <p className='pt-4'>For domain registration renewal information go to <a href='' className='underline text-indigo-400'>Manage Domains</a></p>
                </div>
                </div>
            </BillingNavbar>
        </Page>

    );
}

