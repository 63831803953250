export const COLORS = [
    "#4A90E2",  // Blue
    "#9B59B6",  // Purple
    "#FF69B4",  // Pink
    "#1E90FF",  // Blue
    "#8E44AD",  // Purple
    "#F67280",  // Pink
    "#6495ED",  // Blue
    "#A569BD",  // Purple
    "#FFB6C1",  // Pink
    "#4682B4"   // Blue
];