import CryptoJS from 'crypto-js';

export function encryptData(company_id: string): string {
    const encryptedcompany_id = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(company_id), process.env.REACT_APP_MY_SECRET || "").toString();
    const base64Encoded = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptedcompany_id));
    return base64Encoded;
}

export function decryptData(encryptedcompany_id: string): string {
    const base64Decoded = CryptoJS.enc.Base64.parse(encryptedcompany_id).toString(CryptoJS.enc.Utf8);
    const decryptedcompany_idBytes = CryptoJS.AES.decrypt(base64Decoded, process.env.REACT_APP_MY_SECRET || "");
    const decryptedcompany_id = decryptedcompany_idBytes.toString(CryptoJS.enc.Utf8);
    return decryptedcompany_id;
}