import { useNavigate, useParams } from 'react-router-dom';
import { FaRegCheckCircle } from "react-icons/fa";
import { useEffect } from 'react';
import { useUser } from '../../../contexts/userContext';
import { clearCache, postData } from '../../../components/DataHandler';
import Toaster from '../../../components/Toaster';

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const { moduleName } = useParams<{ moduleName: string }>();
    const { user } = useUser();

    useEffect(() => {
        const paymentStatus = localStorage.getItem(`paymentIntent`);
        if (!paymentStatus) {
            navigate('/cart');
        }
        Toaster.show('Compra efetuada com sucesso.', 'success');

    }, [moduleName, navigate]);

    const handleRedirect = () => {
        handleBuy();
        localStorage.removeItem(`paymentIntent`);
        navigate(`/marketplace`);
    }

    const handleBuy = async () => {
        const idToken = user ? user!.idToken : null;
        const response = await postData(`/api/order/cart/buy`, {}, idToken);

        if (response.status === 200 || response.status === 201) {
            clearCache('/api/order/cart');
            clearCache('/api/modules/');
        } else {
            //  Toaster.show('Não foi possível efetuar a compra. Tente novamente.', 'error');
            console.error('Buy failed');
        }
    }

    return (
        <div className="min-h-screen bg-green-50 flex flex-col justify-center items-center">
            <div className="bg-white p-10 rounded-lg shadow-lg max-w-md text-center">
                <div className='w-full flex justify-center pb-4'>
                    <FaRegCheckCircle size={50} color='rgb(22 163 74)' />
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-2">Pagamento bem-sucedido!</h2>
                <p className="text-gray-600 mb-6">Obrigado pela sua compra. O seu pagamento foi processado com sucesso.</p>
                <button onClick={handleRedirect} className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75">
                    Voltar para o marketplace
                </button>
            </div>
        </div>
    );
};

export default PaymentSuccess;
