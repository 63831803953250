import React, { ReactNode } from 'react';
import './billingNavbar.styles.scss'
import { useNavigate } from 'react-router-dom';
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { TbReceiptEuro } from "react-icons/tb";
import { MdPayments } from "react-icons/md";
import { IoMailUnreadOutline } from "react-icons/io5";

interface BillingNavbarProps {
    children: ReactNode;
}

export default function BillingNavbar({ children }: BillingNavbarProps) {
    const navigate = useNavigate();
    const lang = navigator.language || 'en';

    const iconMap: { [key: string]: React.ReactNode } = {
        "/invoiceDocs": <LiaFileInvoiceDollarSolid size={32} />,
        "/billage": <TbReceiptEuro size={32} />,
        "/payment": <MdPayments size={32} />,
        "/subscriptions": <IoMailUnreadOutline size={32} />,
    }

    const Element = ({ text, link }: { text: string, link: string }) => {
        let selected = window.location.pathname.includes(link);
        return (
            <li style={{ transition: "all 0.3s ease-in-out" }} className={`${selected ? " border-dashBlue bg-zinc-100" : "border-white bg-transparent"} transition-all duration-300 ease-in-out border-l-4 py-2 px-4`}>
                <div onClick={() => navigate(`/billing${link}`)}>
                    <div className="cursor-pointer hidden miledois:block">
                        {text}
                    </div>
                    <div className="group cursor-pointer block miledois:hidden">
                        {iconMap[link]}
                        <span className='absolute left-[50%] translate-y-3 translate-x-[-50%] quatro:translate-x-0 quatro:left-auto miledois:translate-y-[-2em] miledois:translate-x-12 scale-0 group-hover:scale-100 transition-all duration-100 ease-in-out bg-zinc-100 rounded-xl p-2'>
                            <p className='text-sm text-zinc-500 text-center'>
                                {text}
                            </p>
                        </span>
                    </div>
                </div>
            </li>
        );
    }
    return (
        <div className="container flex flex-col miledois:flex-row ">
            <nav className="navbar">
                <ul className='flex miledois:flex-col gap-1'>
                    <Element text={lang === "pt" ? "Faturas e documentos" : "Invoices and documents"} link="/invoiceDocs" />
                    <Element text={lang === "pt" ? "Utilização" : "Usage"} link="/billage" />
                    <Element text={lang === "pt" ? "Pagamento" : "Payment"} link="/payment" />
                    <Element text={lang === "pt" ? "Subscrições" : "Subscriptions"} link="/subscriptions" />
                </ul>
            </nav>
            <main className="p-6 mil:p-8 w-full h-full">
                {children}
            </main>
        </div>
    );
}