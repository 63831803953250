import React, { useEffect } from 'react';

import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';

// Components
import Navbar from './components/Navbar';

// Pages
import Login from './pages/Login';
import Home from './pages/Home';
import NotFoundPage from './pages/NotFound';
import Settings from './pages/Settings';
import { UserProvider, useUser } from './contexts/userContext';
import AllTeams from './pages/teams/AllTeams';
import Team from './pages/teams/Team';
import InvoiceDocs from './pages/billing/invoiceDocs';
import Payments from './pages/billing/payment';
import Subscriptions from './pages/billing/subscriptions';
import UsageStats from './pages/billing/usageStats';
import HelpCenter from './pages/HelpCenter';
import { Toaster } from 'react-hot-toast';
import Marketplace from './pages/marketplace/MarketPlace';
import ModulePage from './pages/marketplace/ModulePage';
import ShoppingCar from './pages/marketplace/ShoppingCart';
import PaymentSuccess from './pages/marketplace/purchase/PaymentSuccessfull';
import PaymentFailure from './pages/marketplace/purchase/PaymentCancel';

function App() {
	const Layout = ({ children }: { children: any }) => {
		const { user } = useUser();
		const navigate = useNavigate();
		useEffect(() => {
			if (!user) {
				navigate('/');
			}
		}, [user, navigate]);
		return (
			<>
				{user && <div className='w-full'>
					<Navbar />
					<div className='text-[var(--black)] pt-[5rem] oito:pt-0 oito:pl-[17rem]'>
						{children}
					</div>
				</div>}
			</>
		);
	};

	return (
		<UserProvider>
			<Toaster />
			<BrowserRouter>
				<Routes>
					<Route index element={<Login />} />
					<Route path="/home" element={<Layout><Home /></Layout>} />
					<Route path="/settings" element={<Layout><Settings /></Layout>} />
					<Route path="/teams" element={<Layout><AllTeams /></Layout>} />
					<Route path="/cart" element={<Layout><ShoppingCar /></Layout>} />
					<Route path="/billing/invoiceDocs" element={<Layout><InvoiceDocs /></Layout>} />
					<Route path="/billing/payment" element={<Layout><Payments /></Layout>} />
					<Route path="/billing/billage" element={<Layout><UsageStats /></Layout>} />
					<Route path="/billing/subscriptions" element={<Layout><Subscriptions /></Layout>} />
					<Route path="/help" element={<Layout><HelpCenter /></Layout>} />
					<Route path="/teams/:teamName" element={<Layout><Team /></Layout>} />
					<Route path="/marketplace" element={<Layout><Marketplace /></Layout>} />
					<Route path="/marketplace/:moduleName" element={<Layout><ModulePage /></Layout>} />
					<Route path="/payment/success" element={<PaymentSuccess />} />
					<Route path="/payment/failure" element={<PaymentFailure />} />
					<Route path="*" element={<NotFoundPage />} />
				</Routes>
			</BrowserRouter>
		</UserProvider>
	);
}

export default App;
