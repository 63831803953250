const template = `<!DOCTYPE html
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html dir="ltr" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="pt">

<head>
  <meta charset="UTF-8">
  <meta content="width=device-width, initial-scale=1" name="viewport">
  <meta name="x-apple-disable-message-reformatting">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta content="telephone=no" name="format-detection">
  <title>Vision D </title><!--[if (mso 16)]>
    <style type="text/css">
    a {text-decoration: none;}
    </style>
    <![endif]--><!--[if gte mso 9]><style>sup { font-size: 100% !important; }</style><![endif]--><!--[if gte mso 9]>
<xml>
    <o:OfficeDocumentSettings>
    <o:AllowPNG></o:AllowPNG>
    <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
</xml>
<![endif]--><!--[if !mso]><!-- -->
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i"><!--<![endif]-->
  <style type="text/css">
    .rollover:hover .rollover-first {
      max-height: 0px !important;
      display: none !important;
    }

    button {
      cursor: default;
      transition: all 0.3s ease-in-out;
    }

    button:hover {
      background-color: #c49a4e !important;
      cursor: pointer;
    }

    .rollover:hover .rollover-second {
      max-height: none !important;
      display: block !important;
    }

    .rollover span {
      font-size: 0;
    }

    u+.body img~div div {
      display: none;
    }

    #outlook a {
      padding: 0;
    }

    span.MsoHyperlink,
    span.MsoHyperlinkFollowed {
      color: inherit;
      mso-style-priority: 99;
    }



    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: none !important;
      font-size: inherit !important;
      font-family: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
    }

    .es-desk-hidden {
      display: none;
      float: left;
      overflow: hidden;
      width: 0;
      max-height: 0;
      line-height: 0;
      mso-hide: all;
    }



    @media only screen and (min-width:214px) {
      .chek-hidden {
        position: absolute;
        visibility: hidden;
        height: 0px;
        width: 0px;
        max-height: 0px;
        overflow: hidden;
        opacity: 0;
      }

      lebel,
      label:hover {
        cursor: pointer !important;
      }

      .vn,
      .nv,
      [class*="w-level_"],
      [class*="d-level_"] {
        visibility: hidden;
        display: none;
        opacity: 0;
        transform: scale(0);
        height: 0;
        max-height: 0px;
        line-height: 0px;
        mso-hide: all;
      }

      #second-option:checked~.choose-zone .nv,
      #second-option:not(:checked)~.choose-zone .vn,
      #toLevel_white_0:checked~.choose-zone.goods .w-level_0,
      #toLevel_white_1:checked~.choose-zone.goods .w-level_1,
      #toLevel_white_2:checked~.choose-zone.goods .w-level_2,
      #toLevel_dark_0:checked~.choose-zone.goods .d-level_0,
      #toLevel_dark_1:checked~.choose-zone.goods .d-level_1,
      #toLevel_dark_2:checked~.choose-zone.goods .d-level_2,
      #toLevel_white_1:checked~#toLevel_dark_0:checked~.choose-zone.goods .w-level_3,
      #toLevel_white_2:checked~#toLevel_dark_0:checked~.choose-zone.goods .w-level_4,
      #toLevel_white_0:checked~#toLevel_dark_1:checked~.choose-zone.goods .w-level_5,
      #toLevel_white_0:checked~#toLevel_dark_2:checked~.choose-zone.goods .w-level_6,
      #toLevel_white_1:checked~#toLevel_dark_1:checked~.choose-zone.goods .w-level_7,
      #toLevel_white_1:checked~#toLevel_dark_2:checked~.choose-zone.goods .w-level_8,
      #toLevel_white_2:checked~#toLevel_dark_1:checked~.choose-zone.goods .w-level_9,
      #toLevel_white_2:checked~#toLevel_dark_2:checked~.choose-zone.goods .w-level_10 {
        visibility: visible !important;
        display: inline-block !important;
        opacity: 1 !important;
        transform: scale(1) !important;
        height: initial !important;
        max-height: initial !important;
        line-height: initial !important;
      }

      #second-option:checked~.choose-zone table.nv,
      #second-option:not(:checked)~.choose-zone table.vn,
      #toLevel_white_0:checked~.choose-zone.goods table.w-level_0,
      #toLevel_white_1:checked~.choose-zone.goods table.w-level_1,
      #toLevel_white_2:checked~.choose-zone.goods table.w-level_2,
      #toLevel_dark_0:checked~.choose-zone.goods table.d-level_0,
      #toLevel_dark_1:checked~.choose-zone.goods table.d-level_1,
      #toLevel_dark_2:checked~.choose-zone.goods table.d-level_2 {
        display: table !important;
      }

      #second-option:checked~.choose-zone table tr.nv,
      #second-option:not(:checked)~.choose-zone table tr.vn {
        display: table-row !important;
      }

      #second-option:checked+.choose-zone table tr td.nv,
      #second-option:not(:checked)+.choose-zone table tr td.vn {
        display: table-cell !important;
      }
    }

    @media only screen and (min-width:214px) {
      .chek-hidden {
        position: absolute;
        visibility: hidden;
        height: 0px;
        width: 0px;
        max-height: 0px;
        overflow: hidden;
        opacity: 0
      }

      lebel,
      label:hover {
        cursor: pointer !important
      }

      .vn,
      .nv,
      [class*="w-level_"],
      [class*="d-level_"] {
        visibility: hidden;
        display: none;
        opacity: 0;
        transform: scale(0);
        height: 0;
        max-height: 0px;
        line-height: 0px;
        mso-hide: all
      }

      #second-option:checked~.choose-zone .nv,
      #second-option:not(:checked)~.choose-zone .vn,
      #toLevel_white_0:checked~.choose-zone.goods .w-level_0,
      #toLevel_white_1:checked~.choose-zone.goods .w-level_1,
      #toLevel_white_2:checked~.choose-zone.goods .w-level_2,
      #toLevel_dark_0:checked~.choose-zone.goods .d-level_0,
      #toLevel_dark_1:checked~.choose-zone.goods .d-level_1,
      #toLevel_dark_2:checked~.choose-zone.goods .d-level_2,
      #toLevel_white_1:checked~#toLevel_dark_0:checked~.choose-zone.goods .w-level_3,
      #toLevel_white_2:checked~#toLevel_dark_0:checked~.choose-zone.goods .w-level_4,
      #toLevel_white_0:checked~#toLevel_dark_1:checked~.choose-zone.goods .w-level_5,
      #toLevel_white_0:checked~#toLevel_dark_2:checked~.choose-zone.goods .w-level_6,
      #toLevel_white_1:checked~#toLevel_dark_1:checked~.choose-zone.goods .w-level_7,
      #toLevel_white_1:checked~#toLevel_dark_2:checked~.choose-zone.goods .w-level_8,
      #toLevel_white_2:checked~#toLevel_dark_1:checked~.choose-zone.goods .w-level_9,
      #toLevel_white_2:checked~#toLevel_dark_2:checked~.choose-zone.goods .w-level_10 {
        visibility: visible !important;
        display: inline-block !important;
        opacity: 1 !important;
        transform: scale(1) !important;
        height: initial !important;
        max-height: initial !important;
        line-height: initial !important
      }

      #second-option:checked~.choose-zone table.nv,
      #second-option:not(:checked)~.choose-zone table.vn,
      #toLevel_white_0:checked~.choose-zone.goods table.w-level_0,
      #toLevel_white_1:checked~.choose-zone.goods table.w-level_1,
      #toLevel_white_2:checked~.choose-zone.goods table.w-level_2,
      #toLevel_dark_0:checked~.choose-zone.goods table.d-level_0,
      #toLevel_dark_1:checked~.choose-zone.goods table.d-level_1,
      #toLevel_dark_2:checked~.choose-zone.goods table.d-level_2 {
        display: table !important
      }

      #second-option:checked~.choose-zone table tr.nv,
      #second-option:not(:checked)~.choose-zone table tr.vn {
        display: table-row !important
      }

      #second-option:checked+.choose-zone table tr td.nv,
      #second-option:not(:checked)+.choose-zone table tr td.vn {
        display: table-cell !important
      }
    }

    @media only screen and (max-width:600px) {
      .es-m-p15r {
        padding-right: 15px !important
      }

      .es-m-p15l {
        padding-left: 15px !important
      }

      .es-m-p20 {
        padding: 20px !important
      }

      .es-m-p20r {
        padding-right: 20px !important
      }

      .es-m-p20l {
        padding-left: 20px !important
      }

      .es-m-p20r {
        padding-right: 20px !important
      }

      .es-m-p20l {
        padding-left: 20px !important
      }

      .es-m-p20r {
        padding-right: 20px !important
      }

      .es-m-p20l {
        padding-left: 20px !important
      }

      .es-m-p20b {
        padding-bottom: 20px !important
      }

      .es-m-p20r {
        padding-right: 20px !important
      }

      .es-m-p20b {
        padding-bottom: 20px !important
      }

      .es-m-p20l {
        padding-left: 20px !important
      }

      *[class="gmail-fix"] {
        display: none !important
      }

      p,
      a {
        line-height: 150% !important
      }

      h1,
      h1 a {
        line-height: 120% !important
      }

      h2,
      h2 a {
        line-height: 120% !important
      }

      h3,
      h3 a {
        line-height: 120% !important
      }

      h4,
      h4 a {
        line-height: 120% !important
      }

      h5,
      h5 a {
        line-height: 120% !important
      }

      h6,
      h6 a {
        line-height: 120% !important
      }

      h1 {
        font-size: 28px !important;
        text-align: left
      }

      h2 {
        font-size: 24px !important;
        text-align: left
      }

      h3 {
        font-size: 20px !important;
        text-align: left
      }

      h4 {
        font-size: 24px !important;
        text-align: left
      }

      h5 {
        font-size: 20px !important;
        text-align: left
      }

      h6 {
        font-size: 16px !important;
        text-align: left
      }

      .es-header-body h1 a,
      .es-content-body h1 a,
      .es-footer-body h1 a {
        font-size: 28px !important
      }

      .es-header-body h2 a,
      .es-content-body h2 a,
      .es-footer-body h2 a {
        font-size: 24px !important
      }

      .es-header-body h3 a,
      .es-content-body h3 a,
      .es-footer-body h3 a {
        font-size: 20px !important
      }

      .es-header-body h4 a,
      .es-content-body h4 a,
      .es-footer-body h4 a {
        font-size: 24px !important
      }

      .es-header-body h5 a,
      .es-content-body h5 a,
      .es-footer-body h5 a {
        font-size: 20px !important
      }

      .es-header-body h6 a,
      .es-content-body h6 a,
      .es-footer-body h6 a {
        font-size: 16px !important
      }

      .es-menu td a {
        font-size: 16px !important
      }

      .es-header-body p,
      .es-header-body a {
        font-size: 16px !important
      }

      .es-content-body p,
      .es-content-body a {
        font-size: 16px !important
      }

      .es-footer-body p,
      .es-footer-body a {
        font-size: 16px !important
      }

      .es-infoblock p,
      .es-infoblock a {
        font-size: 12px !important
      }

      .es-m-txt-c,
      .es-m-txt-c h1,
      .es-m-txt-c h2,
      .es-m-txt-c h3,
      .es-m-txt-c h4,
      .es-m-txt-c h5,
      .es-m-txt-c h6 {
        text-align: center !important
      }

      .es-m-txt-r,
      .es-m-txt-r h1,
      .es-m-txt-r h2,
      .es-m-txt-r h3,
      .es-m-txt-r h4,
      .es-m-txt-r h5,
      .es-m-txt-r h6 {
        text-align: right !important
      }

      .es-m-txt-j,
      .es-m-txt-j h1,
      .es-m-txt-j h2,
      .es-m-txt-j h3,
      .es-m-txt-j h4,
      .es-m-txt-j h5,
      .es-m-txt-j h6 {
        text-align: justify !important
      }

      .es-m-txt-l,
      .es-m-txt-l h1,
      .es-m-txt-l h2,
      .es-m-txt-l h3,
      .es-m-txt-l h4,
      .es-m-txt-l h5,
      .es-m-txt-l h6 {
        text-align: left !important
      }

      .es-m-txt-r img,
      .es-m-txt-c img,
      .es-m-txt-l img {
        display: inline !important
      }

      .es-m-txt-r .rollover:hover .rollover-second,
      .es-m-txt-c .rollover:hover .rollover-second,
      .es-m-txt-l .rollover:hover .rollover-second {
        display: inline !important
      }

      .es-m-txt-r .rollover span,
      .es-m-txt-c .rollover span,
      .es-m-txt-l .rollover span {
        line-height: 0 !important;
        font-size: 0 !important
      }

      .es-spacer {
        display: inline-table
      }





      .es-m-fw,
      .es-m-fw.es-fw,
      .es-m-fw {
        display: block !important
      }


      .es-m-il,
      .es-m-il,
      .es-social,
      .es-social td,
      .es-menu {
        display: inline-block !important
      }

      .es-adaptive table,
      .es-left,
      .es-right {
        width: 100% !important
      }

      .es-content table,
      .es-header table,
      .es-footer table,
      .es-content,
      .es-footer,
      .es-header {
        width: 100% !important;
        max-width: 600px !important
      }

      .adapt-img {
        width: 100% !important;
        height: auto !important
      }

      .es-mobile-hidden,
      .es-hidden {
        display: none !important
      }

      .es-desk-hidden {
        width: auto !important;
        overflow: visible !important;
        float: none !important;
        max-height: inherit !important;
        line-height: inherit !important
      }

      tr.es-desk-hidden {
        display: table-row !important
      }

      table.es-desk-hidden {
        display: table !important
      }

      td.es-desk-menu-hidden {
        display: table-cell !important
      }

      .es-menu td {
        width: 1% !important
      }

      table.es-table-not-adapt,
      .esd-block-html table {
        width: auto !important
      }

      .es-social td {
        padding-bottom: 10px
      }

      .h-auto {
        height: auto !important
      }

      .m-c-p16r {
        padding-right: 8vw
      }

      .h-auto,
      table.h-auto {
        height: auto !important
      }
    }

    @media screen and (max-width:384px) {
      .mail-message-content {
        width: 414px !important
      }
    }
  </style>
</head>

<body class="body" style="width:100%;height:100%;padding:0;Margin:0">
  <div dir="ltr" class="es-wrapper-color" lang="pt" style="background-color:#12022F"><!--[if gte mso 9]>
			<v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
				<v:fill type="tile" color="#12022f"></v:fill>
			</v:background>
		<![endif]-->
    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:100%;background-repeat:no-repeat;background-position:center top;background-color:#12022F">
      <tr>
        <td valign="top" style="padding:0;Margin:0">
          <table cellpadding="0" cellspacing="0" class="es-content" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;width:100%;table-layout:fixed !important">
            <tr>
              <td class="es-m-p15r es-m-p15l" align="center" style="padding:0;Margin:0">
                <table bgcolor="#ffffff" class="es-content-body" align="center" cellpadding="0" cellspacing="0"
                  style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:640px">
                  <tr>
                    <td align="left" style="padding:0;Margin:0;padding-top:30px;padding-right:40px;padding-left:40px">
                      <table cellpadding="0" cellspacing="0" width="100%"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                        <tr>
                          <td align="center" valign="top" style="padding:0;Margin:0;width:560px">
                            <table cellpadding="0" cellspacing="0" width="100%"
                              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                              <tr class="es-mobile-hidden">
                                <td align="center" height="44" style="padding:0;Margin:0"></td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td class="es-m-p20" align="left" bgcolor="#ffffff"
                      style="padding:40px;Margin:0;background-color:#ffffff;border-radius:20px 20px 0px 0px">
                      <table cellpadding="0" cellspacing="0" width="100%"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                        <tr>
                          <td align="left" style="padding:0;Margin:0;width:560px">
                            <table cellpadding="0" cellspacing="0" width="100%"
                              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                              <tr>
                                <td align="center"
                                  style="padding:0;Margin:0;padding-top:15px;padding-bottom:15px;font-size:0px"><a
                                    target="_blank" href="https://visiond.pt" class="rollover"
                                    style="mso-line-height-rule:exactly;text-decoration:underline;color:#391484;font-size:18px"><img
                                      src="https://fbuvalx.stripocdn.email/content/guids/videoImgGuid/images/design_sem_nome_5_TGG.png"
                                      alt=""
                                      style="display:block;font-size:18px;border:0;outline:none;text-decoration:none"
                                      class="rollover-first" height="43"><span style="font-size:0;mso-hide:all"><img
                                        class="rollover-second"
                                        style="display:none;font-size:18px;border:0;outline:none;text-decoration:none;max-height:0px"
                                        src="https://fbuvalx.stripocdn.email/content/guids/07608863-2a85-43a5-9e2e-efb0e47a96d7/images/design_sem_nome_5.png"
                                        height="43"></span></a></td>
                              </tr>
                              <tr>
                                <td align="center" height="40" style="padding:0;Margin:0"></td>
                              </tr>
                              <tr>
                                <td align="center" class="es-m-txt-l es-m-p20r es-m-p20l"
                                  style="padding:0;Margin:0;padding-right:40px;padding-left:40px;padding-bottom:25px">
                                  <h1
                                    style="Margin:0;font-family:lato, 'helvetica neue', helvetica, arial, sans-serif;mso-line-height-rule:exactly;letter-spacing:0;font-size:24px;font-style:normal;font-weight:bold;line-height:29px;color:#000000;text-transform:uppercase">
                                    <strong>Welcome to our</strong>
                                  </h1>
                                  <h1
                                    style="Margin:0;font-family:lato, 'helvetica neue', helvetica, arial, sans-serif;mso-line-height-rule:exactly;letter-spacing:0;font-size:24px;font-style:normal;font-weight:bold;line-height:29px;color:#000000;text-transform:uppercase">
                                    Modules</h1>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" style="padding:0;Margin:0;width:560px">
                            <table cellpadding="0" cellspacing="0" width="100%" bgcolor="#AACAEF"
                              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:separate;border-spacing:0px;border-left:1px dotted #AACAEF;border-right:1px dotted #AACAEF;border-top:1px dotted #AACAEF;border-bottom:1px dotted #AACAEF;background:linear-gradient(153.06deg, #dae6f7 -0.31%, #f9f1fb 99.69%);border-radius:20px">
                              <tr>
                                <td align="center" class="es-m-txt-l es-m-p20r es-m-p20l"
                                  style="Margin:0;padding-top:30px;padding-right:40px;padding-left:40px;padding-bottom:30px">
                                  <p
                                    style="Margin:0;mso-line-height-rule:exactly;font-family:lato, 'helvetica neue', helvetica, arial, sans-serif;line-height:27px;letter-spacing:0;color:#111111;font-size:18px">
                                    Foi convidado para pretencer a uma equipa.</p>
                                  <p
                                    style="Margin:0;mso-line-height-rule:exactly;font-family:lato, 'helvetica neue', helvetica, arial, sans-serif;line-height:27px;letter-spacing:0;color:#111111;font-size:18px">
                                    Clique no botão abaixo para aceitar o convite</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td class="es-m-p20r es-m-p20l" align="left" bgcolor="#ffffff"
                      style="padding:40px;Margin:0;background-color:#ffffff">
                      <table cellpadding="0" cellspacing="0" width="100%"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                        <tr>
                          <td align="center" valign="top" style="padding:0;Margin:0;width:560px">
                            <table cellpadding="0" cellspacing="0" width="100%"
                              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                              <tr>
                                <div align="center" class="es-m-p20b"
                                  style="padding:0;Margin:0;padding-right:20px;padding-left:20px">
                                  <span class="es-button-border"
                                    style="border-style:solid;border-color:#FFDDA9;background:#FFDDA9;border-width:0px 0px 0px 0px;display:inline-block;border-radius:30px;width:auto;">
                                    <a id="confirmLink" href="#">
                                      <button id="confirmButton" type="button" class="es-button"
                                        style="mso-style-priority:100 !important;text-decoration:none !important;mso-line-height-rule:exactly;color:#000000;font-size:20px;padding:15px 30px 15px 30px;display:inline-block;background:#FFDDA9;border-radius:30px;font-family:lato, 'helvetica neue', helvetica, arial, sans-serif;font-weight:normal;font-style:normal;line-height:24px;width:auto;text-align:center;letter-spacing:0;mso-padding-alt:0;mso-border-alt:10px solid #FFDDA9">
                                        CONFIRMAR
                                      </button>
                                    </a>
                                  </span>
                                </div>
                              </tr>
                              <tr>
                                <td align="center"
                                  style="padding:0;Margin:0;padding-top:40px;padding-right:15px;padding-left:15px">
                                  <p
                                    style="Margin:0;mso-line-height-rule:exactly;font-family:'Exo 2', sans-serif;line-height:21px;letter-spacing:0;color:#666666;font-size:14px">
                                    <u>​</u>
                                  </p>
                                  <p style="Margin:0;mso-line-height-rule:exactly;font-family:'Exo 2', sans-serif;line-height:21px;letter-spacing:0;color:#666666;font-size:14px"
                                    align="left"><u>​</u></p>
                                  <p style="Margin:0;mso-line-height-rule:exactly;font-family:'Exo 2', sans-serif;line-height:21px;letter-spacing:0;color:#666666;font-size:14px"
                                    align="left">Obrigado,</p>
                                  <p style="Margin:0;mso-line-height-rule:exactly;font-family:'Exo 2', sans-serif;line-height:21px;letter-spacing:0;color:#666666;font-size:14px"
                                    align="left">Equipa Vision D</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td class="es-m-p20b es-m-p20r es-m-p20l" align="left" bgcolor="#ffffff"
                      style="Margin:0;padding-right:40px;padding-left:40px;padding-top:10px;padding-bottom:40px;background-color:#ffffff;border-radius:0px 0px 20px 20px;background-size:contain">
                      <table cellpadding="0" cellspacing="0" width="100%"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                        <tr>
                          <td align="left" style="padding:0;Margin:0;width:560px">
                            <table cellpadding="0" cellspacing="0" width="100%"
                              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                              <tr>
                                <td align="center"
                                  style="padding:0;Margin:0;padding-top:15px;padding-bottom:15px;font-size:0px"><a
                                    target="_blank" href="https://viewstripo.email/"
                                    style="mso-line-height-rule:exactly;text-decoration:underline;color:#391484;font-size:18px"><img
                                      src="https://fbuvalx.stripocdn.email/content/guids/videoImgGuid/images/design_sem_nome_5_p05.png"
                                      alt=""
                                      style="display:block;font-size:18px;border:0;outline:none;text-decoration:none"
                                      height="23"></a></td>
                              </tr>
                              <tr>
                                <td align="center" style="padding:0;Margin:0;padding-top:15px;font-size:0">
                                  <table cellpadding="0" cellspacing="0" class="es-table-not-adapt es-social"
                                    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                    <tr>
                                      <td align="center" valign="top" style="padding:0;Margin:0;padding-right:15px"><a
                                          target="_blank" href="https://www.instagram.com/visiondpt"
                                          style="mso-line-height-rule:exactly;text-decoration:underline;color:#391484;font-size:18px"><img
                                            title="Instagram"
                                            src="https://fbuvalx.stripocdn.email/content/assets/img/social-icons/logo-black/instagram-logo-black.png"
                                            alt="Inst" width="32" height="32"
                                            style="display:block;font-size:18px;border:0;outline:none;text-decoration:none"></a>
                                      </td>
                                      <td align="center" valign="top" style="padding:0;Margin:0;padding-right:15px"><a
                                          target="_blank" href="https://www.youtube.com/@visiondpt"
                                          style="mso-line-height-rule:exactly;text-decoration:underline;color:#391484;font-size:18px"><img
                                            title="Youtube"
                                            src="https://fbuvalx.stripocdn.email/content/assets/img/social-icons/logo-black/youtube-logo-black.png"
                                            alt="Yt" width="32" height="32"
                                            style="display:block;font-size:18px;border:0;outline:none;text-decoration:none"></a>
                                      </td>
                                      <td align="center" valign="top" width="null" style="padding:0;Margin:0"><a
                                          target="_blank" href="https://pt.linkedin.com/company/visiondpt"
                                          style="mso-line-height-rule:exactly;text-decoration:underline;color:#391484;font-size:18px"><img
                                            title="Linkedin"
                                            src="https://fbuvalx.stripocdn.email/content/assets/img/social-icons/logo-black/linkedin-logo-black.png"
                                            alt="In" width="32" height="32"
                                            style="display:block;font-size:18px;border:0;outline:none;text-decoration:none"></a>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td align="center" style="padding:0;Margin:0;padding-top:15px">
                                  <p
                                    style="Margin:0;mso-line-height-rule:exactly;font-family:'Exo 2', sans-serif;line-height:27px;letter-spacing:0;color:#666666;font-size:18px">
                                    Siga-nos para ficar a par das novidades!</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table cellpadding="0" cellspacing="0" class="es-content" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;width:100%;table-layout:fixed !important">
            <tr>
              <td align="center" style="padding:0;Margin:0">
                <table bgcolor="transparent" class="es-content-body" align="center" cellpadding="0" cellspacing="0"
                  style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:640px">
                  <tr>
                    <td align="left"
                      style="Margin:0;padding-right:20px;padding-left:20px;padding-top:40px;padding-bottom:40px">
                      <table width="100%" cellspacing="0" cellpadding="0"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                        <tr>
                          <td valign="top" align="center" style="padding:0;Margin:0;width:560px">
                            <table width="100%" cellspacing="0" cellpadding="0"
                              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                              <tr>
                                <td align="center" style="padding:0;Margin:0;display:none"></td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>

</body>

</html>`

export default template;