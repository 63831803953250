import React, { ReactNode } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';


export default function Page({ children }: { children: ReactNode }) {


    return (
        <div className='w-full h-screen'>
                <Header />
                <div className='w-full'>
                    <div className='p-4 mil:p-10 bg-white'>
                        {children}
                    </div>
                    <Footer />
                </div>
        </div>
    );
}

