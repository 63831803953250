

import AWS from 'aws-sdk';

// Configure AWS SDK
const s3Client = new AWS.S3({
    region: process.env.REACT_APP_AWS_REGION || "",
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_KEY || "",
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY ||""},
  });

const uploadImagesToS3 = async (files: File[], bucketName: string): Promise<string[]> => {
  const uploadPromises = files.map(async (file) => {
    const params = {
      Bucket: bucketName,
      Key: "marketplace/" + file.name,
      Body: file,
      ContentType: file.type,
    };

    try {
      const data = await s3Client.upload(params).promise();
      console.log('File uploaded successfully:', data.Location);
      return data.Location;
    } catch (error) {
      console.error('Error uploading file:', error);
      return 'error';
    }
  });

  return Promise.all(uploadPromises);
};

export default uploadImagesToS3;