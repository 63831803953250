import React, { useCallback, useEffect, useState } from 'react';
import Loading from '../../components/Loading/loading';
import "../../components/customScroll.styles.scss";
import { IoIosStar } from "react-icons/io";
import { IoIosStarHalf } from "react-icons/io";
import { IoIosStarOutline } from "react-icons/io";
import { clearCache, fetchData, postData, getCache } from '../../components/DataHandler';
import Toaster from '../../components/Toaster';
import { useUser } from '../../contexts/userContext';
import { Module } from '../../interfaces/ModuleMarketplace';
import { Review } from '../../interfaces/ModuleReview';

export default function ModuleInfo({ module }: { module: Module }) {
    const lang = navigator.language || 'en';
    const { user } = useUser();
    const [loading, setLoading] = useState(false);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [reviews, setReviews] = useState<Review[]>([]);
    const today = new Date();

    // useEffect(() => {
    //     setLoading(true)
    //     const  storedModuleTemplates = localStorage.getItem(`${query}`);
    //     if ( storedModuleTemplates) {
    //         const templates = JSON.parse( storedModuleTemplates);
    //         setTemplates(templates);
    //     } else {
    //         getModuleTemplates()
    //     }
    //     setLoading(false)
    // }, [query]);

    // const getModuleTemplates = async () => {
    //     try {
    //         const response = await axios.get(`${process.env.REACT_APP_API_URL}/templates/query`, {
    //             headers: {
    //                 'Authorization': `${user!.idToken}`
    //             }
    //         });
    //         setTemplates(response.data);
    //         localStorage.setItem(`${query}`, JSON.stringify(response.data))
    //     } catch (error) {
    //         console.error('Error getting module:', error);
    //     } finally {
    //         setLoading(false)
    //     }
    // }
    function getDayMonthYear(date: Date) {
        if (!date) {
            return '';
        }
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const Rater = () => {

        return (
            <div className='flex'>
                {[1, 2, 3, 4, 5].map((star) => {
                    return (
                        <span
                            style={{
                                color: '#484c9c',
                                fontSize: `23px`,
                            }}
                        >
                            {rating >= star ? <IoIosStar onClick={() => setRating(star)} /> : <IoIosStarOutline onClick={() => setRating(star)} />}
                        </span>
                    )
                })}
            </div>
        );
    };

    function Rating({ rating }: { rating: number }) {
        return (
            <div className='flex'>
                {[1, 2, 3, 4, 5].map((star) => {
                    return (
                        <span
                            style={{
                                color: '#484c9c',
                                fontSize: `23px`,
                            }}
                        >
                            {rating >= star ? <IoIosStar /> : Math.round(rating) === star ? <IoIosStarHalf /> : <IoIosStarOutline />}
                        </span>
                    )
                })}
            </div>
        )
    }

    const addReview = async () => {
        if (!comment) {
            Toaster.show('Por favor preencha todos os campos.', 'error');
            return;
        }


        const idToken = user ? user!.idToken : null;
        const newComment = {
            comment: comment,
            rating: rating
        };
        const response = await postData(`/api/reviews/${module.id}`, newComment, idToken);
        if (response.status === 200 || response.status === 201) {
            const updatedReviews = [...reviews,
            {
                ...newComment,
                reviewername: user!.name,
                createdat: today,
                userId: response.data.userID,
                featureId: response.data.featureId
            }];
            clearCache(`/api/reviews/${module.id}`);
            setReviews(updatedReviews);
            setRating(0);
            setComment('');

        } else {
            Toaster.show(`Não foi possível avaliar. Tente novamente.`, 'error');
            console.error('Review module failed');
        }
    }

    const getReviews = useCallback(async () => {
        const idToken = user ? user!.idToken : null;
        if(getCache(`/api/reviews/${module.id}`)){
            setReviews(getCache(`/api/reviews/${module.id}`));
            return;
        }
        setLoading(true);
        const response = await fetchData(`/api/reviews/${module.id}`, idToken);
        if (response.status === 200 || response.status === 201) {
            console.log(response.data);
            setReviews(response.data);
        }
        setLoading(false);
    }, [user, module]);




    useEffect(() => {
        getReviews();
    }, [getReviews]);


    return (
        <div>
            <div className='py-6'>
                <p className='text-2xl text-dashBlue font-bold'>Visao Geral</p>
                <p className='py-4 leading-10'>
                    {module.description}
                </p>
            </div>
            {reviews && reviews.length > 0 ? (
                <div className='flex '>
                    {reviews.map((review, index) => (
                        <div key={index} className='py-4'>
                            <div className='flex gap-4'>
                                <div className='flex items-center gap-4 group relative'>
                                    <div className='w-12 h-12 bg-black text-white rounded-full flex items-center justify-center'>
                                        <span>{review.reviewername.charAt(0)}</span>
                                    </div>
                                    <span className='absolute top-[2.25rem] left-6 scale-0 group-hover:scale-100 transition-all duration-200 ease-in-out w-fit rounded-2xl p-2' style={{ backgroundColor: 'rgba(245, 245, 245, 0.8)' }}>
                                        {review.reviewername}
                                    </span>


                                    <div className='flex flex-col'>
                                        <p className='text-sm text-zinc-400'>Data de Avaliação: {getDayMonthYear((review.createdat && new Date(review.createdat)) || today)}</p>
                                    </div>
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <Rating rating={review.rating} />
                                </div>
                            </div>
                            <div className='py-4'>
                                <p>{review.comment}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className='w-full flex justify-center pb-8'>
                    <p>No reviews available. You can review the module yourself.</p>
                </div>
            )}
            <hr></hr>
            <p className='pt-4 text-zinc-400'>Adicionar uma nova review</p>
            <div className='py-4'>
                <div className='flex justify-between'>
                    <div className='flex gap-4 items-center'>
                        <div className='flex items-center gap-4 group relative'>
                            <div className='w-12 h-12 bg-black text-white rounded-full flex items-center justify-center'>
                                <span>{user?.name.charAt(0).toLocaleUpperCase() || user?.email.charAt(0).toLocaleUpperCase()}</span>
                            </div>
                            <span className='absolute top-[2.25rem] left-6 scale-0 group-hover:scale-100 transition-all duration-200 ease-in-out w-fit rounded-2xl p-2' style={{ backgroundColor: 'rgba(245, 245, 245, 0.8)' }}>
                                {user?.name}
                            </span>
                            <div className='flex flex-col'>
                                <p className='text-sm text-zinc-400'>Data de Avaliação: {getDayMonthYear(today)}</p>
                            </div>
                        </div>
                        <div className='flex gap-2'>
                            <Rater />
                        </div>
                    </div>
                    <button onClick={addReview} className="bg-dashBlue w-fit text-white py-2 px-4 rounded-full flex items-center justify-center gap-2">
                        <span>Avaliar</span>
                    </button>
                </div>
                <div className='py-4'>
                    <textarea
                        className="w-full outline-none h-10 px-4 py-2 text-sm border border-gray-300 border-solid rounded-xl"
                        placeholder='| Comment'
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                    />
                </div>
            </div>
            <Loading isOpen={loading} />
        </div>

    );
}

