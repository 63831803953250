



import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
    const lang = navigator.language || 'en';
    const element = ({ name, link }: { name: string, link: string }) => {
        return (
                <Link to={""}  className='underline-offset-1 hover:underline flex items-center gap-2 text-indigo-600 text-sm' >
                    <p >{name}</p>
                </Link>

        )
    }

    // TODO: Add proper the links

    return (

        <div className="grid cinco:grid-cols-3 mil:grid-cols-5 bg-zinc-100 p-8 gap-4 justify-center">
            <div className="flex flex-col gap-4">
                <p className="text-lg">{lang === "pt" ? "Contacto" : "Contact"}</p>
                {element({ name: lang === "pt" ? 'Contacto de suporte' : 'Contact support', link: '/x' })}
                {element({ name: lang === "pt" ? 'Contacto de vendas' : 'Contact sales', link: '/x' })}
                <div className="flex gap-2">
                    <FaXTwitter color="#4F46E5" size={20}/>
                    <FaFacebook  color="#4F46E5" size={20}/>
                    <FaLinkedin  color="#4F46E5" size={20}/>
                </div>
            </div>
            <div className="flex flex-col gap-4">
                <p className="text-lg">{lang === "pt" ? "O que fazemos" : "What we do"}</p>
                {element({ name: lang === "pt" ? 'Planos' : 'Plans', link: '/x' })}
                {element({ name: lang === "pt" ? 'Sobre' : 'About', link: '/x' })}
                {element({ name: lang === "pt" ? 'Rede global' : 'Global network' , link: '/x' })}
            </div>
            <div className="flex flex-col gap-4">
                <p className="text-lg">{lang === "pt" ? "Recursos" : "Resources"}</p>
                {element({ name: lang === "pt" ? 'Documentação' : 'Documentation', link: '/x' })}
                {element({ name: 'Blog', link: '/x' })}
                {element({ name: lang === "pt" ? 'Casos de estudo' : 'Case studies', link: '/x' })}
                {element({ name: lang === "pt" ? 'Parceiros' : 'Partners', link: '/x' })}
                {element({ name: 'API', link: '/x' })}
            </div>
            <div className="flex flex-col gap-4">
                <p className="text-lg">{lang === "pt" ? "Suporte" : "Support"}</p>
                {element({ name: lang === "pt" ? 'Centro de ajuda' : 'Help center', link: '/x' })}
                {element({ name: lang === "pt" ? 'Comunidade' : 'Community', link: '/x' })}
                {element({ name: lang === "pt" ? 'Estado do sistema' : 'System status', link: '/x' })}
                {element({ name: lang === "pt" ? 'Segurança & Confiabilidade' : 'Trust & Safety', link: '/x' })}
            </div>
            <div className="flex flex-col gap-4">
                <p className="text-lg">{lang === "pt" ? "Sobre nós" : "About us"}</p>
                {element({ name: lang === "pt" ? 'A nossa equipa' : 'Our team', link: '/x' })}
                {element({ name: lang === "pt" ? 'Carreiras' : 'Careers', link: '/x' })}
                {element({ name: lang === "pt" ? 'Imprensa' : 'Press', link: '/x' })}
                {element({ name: lang === "pt" ? 'Termos e condições' : 'Terms and Conditions', link: '/x' })}
                {element({ name: lang === "pt" ? 'Contrato de assinatura de autoatendimento' : 'Self-serve Subscription Agreement', link: '/x' })}
                {element({ name: lang === "pt" ? 'Política de privacidade' : 'Privacy policy', link: '/x' })}
            </div>
        </div>

    )




}

export default Footer;
